
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'collection-page__product collection-page__product--four collection-page__product--mobile-two' }, _createElement('div', { 'className': 'product-grid-item product-grid-item--boxes js-product-control-wrapper' }, _createElement('div', {
                    'className': 'product-grid-item__inner',
                    'style': { borderRadius: '6px' }
                }, _createElement('div', { 'className': 'product-grid-item__media' }, _createElement('a', {
                    'href': this.url,
                    'className': 'product-grid-item__image product-grid-item__image--slide'
                }, _createElement('picture', {}, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'className': 'first--image three',
                    'loading': 'lazy',
                    'onError': this.onImageError
                })), !!this.image2 ? _createElement('picture', { 'key': '641' }, _createElement('img', {
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'className': 'second--image',
                    'loading': 'lazy',
                    'onError': e => this.onImageError(e, 'image2')
                })) : null), _createElement('div', { 'className': 'product-badges product-badges--topleft' }, _createElement('div', { 'className': 'product-badges__badge' }, this.out_of_stock ? _createElement('div', {
                    'className': 'product-badges__sold-out',
                    'key': '1017'
                }) : null))), _createElement('div', { 'className': 'product-grid-item__info' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'product-grid-item__product-name'
                }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'product-grid-item__price-container' }, _createElement('div', { 'className': 'product-grid-item__price' }, _createElement('span', { 'className': 'money js-product-price' }, this.formatPrice(this.price)), this.on_sale ? _createElement('span', {
                    'className': 'money product-grid-item__compare-at-price js-product-compare-price',
                    'key': '1512'
                }, '\n              ', this.formatPrice(this.compare_at_price), '\n            ') : null), this.on_sale ? _createElement('div', {
                    'className': 'product-grid-item__save-amount product-grid-item__save-amount--static',
                    'key': '1718'
                }, '\n            SAVE\n            ', _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price - this.price))) : null), _createElement('div', { 'className': 'product-control' }, !!this.wheel_mpn ? _createElement('button', {
                    'className': 'button btn--secondary cm_view-on-vehicle-btn',
                    'onClick': () => window.Convermax.openVehicleWheelDialog(this.wheel_mpn),
                    'key': '2014'
                }, '\n            View On Vehicle\n          ') : null, _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'id': 'AddToCartForm--' + this.id,
                    'acceptCharset': 'UTF-8',
                    'className': 'product-form',
                    'encType': 'multipart/form-data'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'form_type',
                    'value': 'product'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'utf8',
                    'value': '\u2713'
                }), _createElement('select', {
                    'className': 'product-control__default-select hidden product-control__default-select--show js-select-variants',
                    'name': 'id',
                    'style': { display: 'none' }
                }, _createElement('option', {
                    'value': this.variant_ids[0],
                    'defaultValue': 'selected',
                    'data-price': this.price * 100,
                    'data-compare-price': this.compare_at_price ? this.compare_at_price * 100 : this.price * 100,
                    'data-available': 'true',
                    'data-title': 'Default Title'
                }, '\n                Default Title\n              ')), _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'data-product-options-add-to-cart': true,
                    'data-variant-id': this.variant_ids[0],
                    'data-sold-out-text': 'Sold Out',
                    'data-add-to-card-text': 'Add to cart',
                    'className': 'button product-control__button js-product-add-to-cart-button'
                }, _createElement('span', { 'className': 'js-product-add-to-cart-button-text' }, ' Add to cart')), _createElement('input', {
                    'type': 'hidden',
                    'name': 'product-id',
                    'value': this.id
                })))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []